
































import { Component, Vue } from "vue-property-decorator";
import SignatureService from "@/services/SignatureService";
import { namespace } from "vuex-class";
import { processError } from "@/utils/ComponentUtils";
import PortalInput from "@/components/common/PortalInput.vue";
import DeclineSignerPayload from "@/dto/signature/DeclineSignerPayload";
import { EventBus } from "@/utils/EventBus";

const AppModule = namespace("App");
@Component({
  components: { PortalInput }
})
export default class DeclineModal extends Vue {

  @AppModule.Action
  private startLoading!: () => void

  @AppModule.Action
  private stopLoading!: () => void

  private describeReason = new DeclineSignerPayload();

  decline(): void {
    this.startLoading();
    SignatureService.declineSigner(this.publicId, this.describeReason).then(
      resp => {
        EventBus.$emit('decline');
        this.$emit('close');
        this.stopLoading();
      },
      error => {
        processError(error, this);
        this.stopLoading();
      }
    )
  }

  get publicId(): string {
    return this.$route.params.publicId;
  }

}
